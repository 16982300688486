import { useTitleContext } from "@/Shared/Providers/TitleContext"
import { Head } from "@inertiajs/react"

const SiteHead = () => {
  const titleContext = useTitleContext()
  if (!titleContext) {
    throw new Error(
      "useChangePageTitle has to be used within <TitleContext.Provider>",
    )
  }
  const { title } = titleContext
  return (
    <Head>
      <title>{title ? `${title} | ElectricERP` : "ElectricERP"}</title>
    </Head>
  )
}

export default SiteHead
