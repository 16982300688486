import { Box, Drawer, Fab, List } from "@mui/material"
import { MdMenu } from "react-icons/md"
import { useState } from "react"
import MenuButtons from "./MenuButtons"

const MobileMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }
  return (
    <>
      <Box
        sx={{
          display: { sm: "flex", md: "none" },
          position: "fixed",
          bottom: "1em",
          left: "1em",
          zIndex: 9999,
        }}>
        <Fab color="primary" onClick={toggleDrawer}>
          <MdMenu />
        </Fab>
      </Box>

      <Drawer anchor={"left"} open={drawerOpen} onClose={toggleDrawer}>
        <List sx={{ p: 2, width: 250, height: "100%" }}>
          <MenuButtons />
        </List>
      </Drawer>
    </>
  )
}

export default MobileMenu
