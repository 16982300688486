import { CustomListItem } from "@/Components/MenuItem"
import { dictionaires, menuItems, reports } from "./menuItems"
import { Section } from "@/Components/MenuItem/Section"
import { MdBook, MdReport } from "react-icons/md"
import { router } from "@inertiajs/core"
import { usePage } from "@inertiajs/react"

const clickElement = (href: string) =>
  href === "/logout" ? router.post(href) : router.get(href)

const MenuButtons = () => {
  const isAdmin = usePage().props.is_admin as boolean
  const roles = usePage().props.roles
  const activeSite = new URL(window.location.href).pathname

  const isItemActive = (redirectTo: string) => {
    return (
      activeSite === redirectTo ||
      (activeSite.startsWith(redirectTo) && redirectTo !== "/")
    )
  }

  return (
    <>
      <div style={{ height: "80%", overflowY: "auto", padding: 10 }}>
        <Section
          title="Słowniki"
          icon={<MdBook color={"black"} />}
          items={dictionaires}
          isExpanded={false}
          textColor="black"
          isAdmin={isAdmin}
        />
        <Section
          title="Raporty"
          icon={<MdReport color={"black"} />}
          items={reports}
          isExpanded={false}
          textColor="black"
          isAdmin={isAdmin}
        />
        {menuItems.map((item, index) => (
          <CustomListItem
            key={index}
            item={item}
            isActive={isItemActive(item.redirectTo || "")}
            textColor={"black"}
            onClick={() => clickElement(item.redirectTo)}
            isAdmin={isAdmin}
          />
        ))}
      </div>
    </>
  )
}

export default MenuButtons
