import { Snackbar, SnackbarProps } from "@mui/material"
import Alert, { AlertColor } from "@mui/material/Alert"

type Props = {
  children?: React.ReactNode
  handleClose: () => void
  open?: boolean
  severity: AlertColor
  snackbarProps?: Partial<SnackbarProps>
}

const Message = ({
  open,
  handleClose,
  children,
  severity,
  snackbarProps = {},
}: Props) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={(_, reason) => reason !== "clickaway" && handleClose()}
      {...snackbarProps}>
      <Alert
        severity={severity}
        elevation={6}
        variant="filled"
        onClose={handleClose}>
        {children}
      </Alert>
    </Snackbar>
  )
}

export default Message
