import { ReactNode } from "react"

import FlashContextProvider from "./FlashContextProvider"
import TitleContextProvider from "./TitleContext"

type Props = {
  children?: ReactNode
}

const Providers = ({ children }: Props) => (
  <TitleContextProvider>
    <FlashContextProvider>{children}</FlashContextProvider>
  </TitleContextProvider>
)

export default Providers
