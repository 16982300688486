import { User } from "@/types"
import { Link } from "@inertiajs/react"
import { Typography } from "@mui/material"
import { Box } from "@mui/system"

interface Props {
  user: User
}

const UserWelcome = ({ user }: Props) => (
  <Box
    width={"100%"}
    display="flex"
    flexDirection={"column"}
    alignItems="center"
    mb={1}
    mt={2}
    justifyItems={"center"}>
    <Typography fontWeight={600}>
      Witaj <Link href={`/account`}>{user.name}!</Link>
    </Typography>
  </Box>
)

export default UserWelcome
