import { usePage } from "@inertiajs/react"
import { useEffect } from "react"
import { ErrorBag, Errors, Page, PageProps } from "@inertiajs/inertia"

import {
  FlashType,
  useFlashContext,
} from "@/Shared/Providers/FlashContextProvider"
import Message from "./Message"

export interface CustomPageProps extends Page<PageProps> {
  props: {
    errors: Errors & ErrorBag
    locale: string
    flash: FlashType
  }
}

const Alerts = () => {
  const flashContext = useFlashContext()
  if (!flashContext) {
    throw new Error(
      "useFlashContext has to be used within <FlashContext.Provider>",
    )
  }
  const { flashes, setFlashes } = flashContext
  const { flash } = usePage<any>().props
  useEffect(
    () =>
      setFlashes((current: FlashType) => ({
        ...current,
        ...flash,
      })),
    [flash, setFlashes],
  )
  const clearMessage = (type: keyof FlashType) =>
    setFlashes((flashes: FlashType) => ({
      ...flashes,
      [type]: false,
    }))
  return (
    <>
      <Message
        open={!!flashes.error}
        handleClose={() => clearMessage("error")}
        severity="error"
        snackbarProps={
          flashes.success ? { sx: { marginBottom: 8 } } : undefined
        }>
        {flashes.error}
      </Message>

      <Message
        open={!!flashes.success}
        handleClose={() => clearMessage("success")}
        severity="success">
        {flashes.success}
      </Message>
    </>
  )
}
export default Alerts
