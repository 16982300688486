import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Box } from "@mui/material"
import Providers from "@/Shared/Providers"
import LoadingBackdrop from "@/Shared/components/LoadingBackdrop"
import Alerts from "@/Shared/components/Alerts"
import { usePage } from "@inertiajs/react"
import Menu from "./Menu"
import ContentLayout from "./ContentLayout"
import SiteHead from "./SiteHead"

const theme = createTheme({
  typography: {
    fontFamily: "'Passeio Test', sans-serif",
  },
})
interface Props {
  children: React.ReactNode
  title?: string
}

const Layout = ({ children }: Props) => {
  const { user }: any = usePage().props

  return (
    <Providers>
      <SiteHead />
      <ThemeProvider theme={theme}>
        <LoadingBackdrop />
        <Alerts />
        <Box
          sx={{
            p: 0,
            m: 0,
            width: "100%",
            height: "100vh",
            overflow: "hidden",
          }}>
          {user?.id ? (
            <Box display={"flex"} flexDirection={"row"}>
              <Menu />
              <ContentLayout>{children}</ContentLayout>
            </Box>
          ) : (
            <>{children}</>
          )}
        </Box>
      </ThemeProvider>
    </Providers>
  )
}

export default Layout
