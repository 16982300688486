import { Box } from "@mui/material"

interface Props {
  children: React.ReactNode
}

const ContentLayout = ({ children }: Props) => {
  return (
    <Box width={"100%"} height={"100vh"}>
      <Box
        p={1}
        sx={{
          overflowY: "auto",
          height: "100vh",
          background: "#F5F5F5",
        }}>
        {children}
      </Box>
    </Box>
  )
}

export default ContentLayout
