import { SidebarItem } from "@/Components/MenuItem"
import { FaTrademark, FaTruck } from "react-icons/fa"
import {
  MdApartment,
  MdCable,
  MdContactPhone,
  MdLogout,
  MdPeople,
  MdBallot,
  MdSupervisedUserCircle,
  MdSettings,
  MdFactory,
  MdPriceChange,
  MdParagliding,
  MdDocumentScanner,
} from "react-icons/md"

export const reports: SidebarItem[] = [
  {
    title: "Idosell - zwroty",
    redirectTo: "/return-reports",
    icon: <MdSupervisedUserCircle color={"black"} />,
  },
]

export const dictionaires: SidebarItem[] = [
  {
    title: "Użytkownicy",
    redirectTo: "/users",
    icon: <MdSupervisedUserCircle color={"black"} />,
    onlyAdmin: true,
  },
  {
    title: "Firmy",
    redirectTo: "/companies",
    icon: <MdPeople color={"black"} />,
  },
  {
    title: "Kontakty",
    redirectTo: "/contacts",
    icon: <MdContactPhone color={"black"} />,
  },
  // {
  //   title: "Pracownicy",
  //   redirectTo: "/employees/show",
  //   icon: <MdPeople color={"black"} />,
  // },
  // {
  //   title: "Klienci",
  //   redirectTo: "/customers",
  //   icon: <MdPeople color={"black"} />,
  // },
  {
    title: "TIM - ustawienia",
    redirectTo: "/tim",
    icon: <FaTrademark color={"black"} />,
  },
  {
    title: "Produkty IAI",
    redirectTo: "/products",
    icon: <MdCable color={"black"} />,
  },
  {
    title: "Produkty zestawieniowe",
    redirectTo: "/bill-products",
    icon: <MdCable color={"black"} />,
  },
  {
    title: "Cennik robocizny",
    redirectTo: "/work-services",
    icon: <MdPriceChange color={"black"} />,
  },
  {
    title: "Szablony zestawień",
    redirectTo: "/material-templates",
    icon: <MdBallot color={"black"} />,
  },
]

export const menuItems = [
  {
    title: "Inwestycje",
    redirectTo: "/investitions",
    icon: <MdApartment color={"black"} />,
  },
  {
    title: "Zamówienia inwestycji",
    redirectTo: "/investition-orders",
    icon: <FaTruck color={"black"} />,
  },
  {
    title: "Magazyny",
    redirectTo: "/warehouses",
    icon: <MdFactory color={"black"} />,
  },
  {
    title: "Ustawienia",
    redirectTo: "/account",
    icon: <MdSettings color={"black"} />,
  },
  {
    title: "Wyloguj",
    redirectTo: "/logout",
    icon: <MdLogout color={"black"} />,
  },
]
